import React from 'react';
import { getMenus } from 'functions/fnRequestAPIs/fnMenus';
import NotFoundLayout from 'layouts/NotFound.layout';
import { initializeApollo } from '@yema/commerce/lib/apollo';

const Page404 = ({ staticContent }) => {
    return <NotFoundLayout staticContent={staticContent} />;
};

export async function getStaticProps({ preview = false }) {
    const apolloClient = initializeApollo();
    const staticContent = await getMenus(null, apolloClient);

    return {
        props: {
            staticContent,
        },

        // Re-generate the page:
        // - when a request comes in
        // - at most once every X seconds
        revalidate: 3600,
    };
}

export default Page404;
